// Hero.tsx
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="relative w-full h-screen bg-white overflow-hidden">
      {/* Geometric shapes background */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 1 }}
        className="absolute inset-0 z-0"
      >
        <div className="absolute top-20 right-20 w-64 h-64 border border-black rotate-45"></div>
        <div className="absolute bottom-20 left-20 w-48 h-48 border border-black rounded-full"></div>
        <div className="absolute top-40 left-1/3 w-32 h-32 border border-black"></div>
      </motion.div>

      {/* Main content */}
      <div className="relative z-10 w-full h-full flex">
        {/* Left section */}
        <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full h-full flex flex-col justify-center desktop:pl-24 laptop:pl-16 tablet:px-8 mobile:px-6">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl font-light text-black mb-6"
          >
            Modern
            <br />
            <span className="font-medium">Coiffure</span>
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="desktop:text-xl laptop:text-lg tablet:text-base mobile:text-sm text-gray-600 mb-12 max-w-md"
          >
            Experience the art of minimalist beauty in our geometric-inspired
            space where style meets simplicity.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <Link
              to="/book"
              className="inline-block px-12 py-4 bg-black text-white hover:bg-gray-900 transition-colors duration-300"
            >
              Book Now
            </Link>
          </motion.div>
        </div>

        {/* Right section - Image */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="desktop:block laptop:block tablet:hidden mobile:hidden w-1/2 h-full"
        >
          <div className="w-full h-full relative">
            <img
              src="https://placehold.co/800x1000/f5f5f5/303030?text=Minimal+Coiffure"
              alt="Minimal Coiffure Space"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4/5 h-4/5 object-cover"
            />
          </div>
        </motion.div>
      </div>

      {/* Floating geometric elements */}
      <motion.div
        animate={{
          rotate: 360,
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: "linear",
        }}
        className="absolute bottom-10 right-10 w-16 h-16 border border-black rotate-45 desktop:block laptop:block tablet:hidden mobile:hidden"
      ></motion.div>
    </div>
  );
};

export default Hero;
